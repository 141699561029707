import React, { useState, useEffect, useCallback } from 'react';
import styles from './AddressForm.module.scss';
import Button from '@components/shared/Button';
import { SMARTY_STREETS } from '@constants/smartyStreets';
import { ERRORS } from '@constants/errors';
import SuggestionItems from '@components/shared/AddressForm/SuggestionList';
import Typography from '@components/shared/Typography';
import { noticeError, addPageAction } from '@helpers/utils/newRelic';
import { MapPin } from '@components/icons/MapPin';
import { useMedia } from '@helpers/hooks';
import {
  fetchAddressSuggestions,
  handleSuggestionClick,
} from '@helpers/utils/smartyStreets';
import {
  linkedToOrderOnline,
  passedThroughToProductSelection,
} from '@constants/newRelicActions';
import { createKeyboardNavigationHandler } from '@helpers/utils/keyboardNavigation';
import { setTimedCookie } from '@helpers/utils/setCookie';

export default function AddressForm({
  componentLocation,
  metadata = {},
  isHero = false,
  isNav = false,
  isModal = false,
  isUppercase = true,
}) {
  const {
    mapPinIconColor,
    addressPlaceHolderCopy,
    addressCTACopy,
    addressLabelCopy,
    addressLabelColor,
    mobileAddressCTACopy,
  } = metadata;
  const isMedia = useMedia();
  const [zipCode, setZipCode] = useState('');
  const [address, setAddress] = useState('');
  const [finalAddress, setFinalAddress] = useState({});
  const [showNoApartmentSuggestions, setShowNoApartmentSuggestions] =
    useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [disableButton, setDisableButton] = useState(false);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [activeIdx, setActiveIdx] = useState(0);
  const location = componentLocation ? componentLocation : 'Homepage';
  const position = isHero
    ? 'Hero'
    : isNav
    ? 'Nav'
    : isModal
    ? 'Modal'
    : 'FinalEpq';

  const { API_KEY } = SMARTY_STREETS;

  const checkFormButton = useCallback(() => {
    if (address && zipCode) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  });

  useEffect(() => {
    checkFormButton();
  }, [zipCode, address, checkFormButton]);

  const onFormSubmit = async (event) => {
    event.preventDefault();
    if (finalAddress) {
      const userData = {
        street_line: finalAddress.street_line,
        city: finalAddress.city,
        state: finalAddress.state,
        zip: finalAddress.zipcode,
        isUnit: finalAddress.entries > 0,
        unitValue: finalAddress.secondary,
      };
      setTimedCookie('address', JSON.stringify(userData), 30);
      addPageAction(passedThroughToProductSelection);

      // Tagular track form submitted
      window.tagular('beam', 'FormSubmitted', {
        '@type': 'redventures.usertracking.v3.FormSubmitted',
        formContext: {
          formType: 'Cart CTA',
          formName: `${position}`,
          formId: 'Homepage',
        },
      });
      window.location.href = '/order-online/product-selection/';
    } else {
      // Navigate to order online page
      addPageAction(linkedToOrderOnline);
      window.location.href = '/order-online/';
    }
  };

  /**
   * Fetch suggestions from Smarty Streets API
   */
  const fetchSuggestions = async (query) => {
    setShowNoApartmentSuggestions(false);

    const addressSuggestions = await fetchAddressSuggestions(query);

    if (addressSuggestions) {
      setSuggestions(addressSuggestions);
      return addressSuggestions;
    } else {
      setSuggestions([]);
      return [];
    }
  };

  const onAddressChange = async (value) => {
    setAddress(value);
    if (!value) {
      setDisableButton(false);
      setAddress('');
      setZipCode('');
      setSuggestions([]);
      setShowSuggestions(false);
      setActiveIdx(0);
    } else {
      const searchQuery = `?auth-id=${API_KEY}&search=${value}&prefer_geolocation=city`;

      try {
        fetchSuggestions(searchQuery);

        if (suggestions) {
          const uniqueSet = new Set();
          const uniqueAddress = [];

          suggestions.map((item) => {
            if (!uniqueSet.has(item.street_line)) {
              uniqueSet.add(item.street_line);
              uniqueAddress.push(item);
            }
            return null;
          });
          setSuggestions(uniqueAddress);
          setShowSuggestions(true);
          setActiveIdx(0);
        } else {
          setShowSuggestions(false);
        }
      } catch (error) {
        noticeError(error, {
          type: ERRORS.SMARTY_STREETS.type,
          message: ERRORS.SMARTY_STREETS.message,
        });
      }
    }
  };

  /**
   * Handle suggestion click
   * @param {Object} item - The suggestion item
   */
  const onSuggestionClick = async (item) => {
    const tagularObj = {
      position: 'ADDRESS AUTOFILL',
      htmlId: '',
      elementType: 'DROP DOWN',
      text: 'Used Suggestion',
      actionOutcome: 'Internallink',
    };

    await handleSuggestionClick(
      item,
      API_KEY,
      location,
      fetchSuggestions,
      setAddress,
      setFinalAddress,
      setZipCode,
      setSuggestions,
      setShowSuggestions,
      setShowNoApartmentSuggestions,
      checkFormButton,
      tagularObj
    );
  };

  /**
   * Handle keyboard navigation
   */
  const handleAddressKeyDown = createKeyboardNavigationHandler({
    onEscape: () => {
      setShowSuggestions(false);
    },
    onArrowDown: () => {
      if (activeIdx >= suggestions.length) {
        setActiveIdx(0);
      } else {
        setActiveIdx(activeIdx + 1);
      }
    },
    onArrowUp: () => {
      if (activeIdx > 0) {
        setActiveIdx(activeIdx - 1);
      }
    },
    onEnter: () => {
      const address = suggestions[activeIdx];
      onSuggestionClick(address);
    },
    afterKeyHandler: () => {
      checkFormButton();
    },
  });

  const onInputClick = () => {
    // handle address input field on click (means form started)
    window.tagular('beam', 'FormStarted', {
      '@type': 'redventures.usertracking.v3.FormStarted',
      formContext: {
        formType: 'ADDRESS CHECK FORM',
        formName: `ADDRESS INPUT FIELD ${location}`,
        formId: '',
      },
    });
  };

  const onSuggestionKeyDown = (event, suggestion) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      onSuggestionClick(suggestion);
    }
  };

  const onCloseClick = () => {
    // Tagular track 'close suggestions' button clicked
    window.tagular('beam', 'ElementClicked', {
      '@type': 'redventures.usertracking.v3.ElementClicked',
      webElement: {
        location: location,
        position: 'ADDRESS AUTOFILL',
        htmlId: '',
        elementType: 'DROP DOWN',
        text: 'Closed suggestions',
      },
      actionOutcome: '',
    });

    setShowSuggestions(false);
    setSuggestions([]);
  };

  return (
    <form
      onSubmit={onFormSubmit}
      autoComplete="off"
      className={styles.addressForm}
    >
      <div
        className={
          isHero
            ? ''
            : isNav
            ? styles.isNavContainer
            : isModal
            ? styles.isModalContainer
            : styles.nonHeroContainer
        }
      >
        <Typography
          variant="h2smaller"
          className={`${styles.addressHeadline} ${
            !isNav && styles.heroAddressHeadline
          }`}
        >
          <span style={{ color: addressLabelColor }}>
            {addressLabelCopy || 'Find special offers in your area'}
          </span>
        </Typography>
        <fieldset
          className={`${styles.fieldset} ${
            isNav
              ? styles.isNavFieldSet
              : !isHero && !isNav
              ? styles.nonHeroFieldset
              : ''
          }`}
        >
          <div
            className={`${styles.inputWrapper} ${
              isNav
                ? styles.isNavInputWrapper
                : !isHero && !isNav
                ? styles.nonHeroInputWrapper
                : ''
            }`}
          >
            <div className={styles.mapPin}>
              <MapPin color={mapPinIconColor} />
            </div>
            <input
              value={address}
              onChange={(e) => onAddressChange(e.target.value)}
              name="address"
              className={`${styles.textInput} ${styles.textInputColor}`}
              onKeyDown={(e) => handleAddressKeyDown(e)}
              onClick={onInputClick}
              placeholder={addressPlaceHolderCopy || 'Enter your address'}
              data-private="lipsum"
            />
            {showSuggestions && (
              <div className={styles.suggestionsList}>
                <ul className={styles.suggestionsListItems}>
                  <SuggestionItems
                    items={suggestions}
                    onSuggestionClick={onSuggestionClick}
                    activeIdx={activeIdx}
                    handleKeyDown={onSuggestionKeyDown}
                  />
                </ul>
                {showNoApartmentSuggestions && (
                  <div
                    className={styles.suggestionListNoApartments}
                    onClick={() => {
                      setAddress('');
                      setShowNoApartmentSuggestions(false);
                      onCloseClick();
                    }}
                  >
                    <Typography variant="p1">
                      Sorry, no secondary apartment suggestions found for this
                      address.
                    </Typography>
                  </div>
                )}
                <div
                  className={styles.suggestionsListClose}
                  onClick={onCloseClick}
                >
                  <Typography
                    variant="p1"
                    className={styles.suggestionsListCloseCopy}
                  >
                    Close address suggestions
                  </Typography>
                </div>
              </div>
            )}
          </div>
          <Button
            isDisabled={address !== '' && disableButton}
            className={`${styles.btn} ${
              isNav
                ? styles.isNavBtn
                : !isHero && !isNav
                ? styles.nonHeroBtn
                : ''
            } ${isUppercase && styles.uppercaseBtn}`}
          >
            {isNav && isMedia.mobile ? mobileAddressCTACopy : addressCTACopy}
          </Button>
        </fieldset>
      </div>
    </form>
  );
}
